import React, { Component } from 'react';
import {Route} from "react-router-dom";
import Offers from '../offers/Offers';
import OfferDetail from '../offers/OfferDetail';

// import Batches from './Batches';
// import AttendanceLog from './AttendanceLog';
// import PlanningTool from './PlanningTool';
// import ProductionPlan from './ProductionPlan';

class AppRoutes extends Component {
  render() {
    return (
    	<React.Fragment>
        <Route exact path="/" component={Offers}/>
        <Route exact path="/offers/:shortCode" component={Offers}/>
        <Route exact path="/offer-detail" component={OfferDetail}/>
      </React.Fragment>
    )
  }
}

export default AppRoutes;

// <Route exact path="/attendance-log" component={AttendanceLog}/>
// <Route exact path="/planning-tool" component={PlanningTool}/>
// <Route exact path="/production-plan" component={ProductionPlan}/>
// <Route exact path="/donor-human-stool" component={SubstanceLots}/>
// <Route exact path="/batches" component={Batches}/>
