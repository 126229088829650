import React, { Component } from 'react';
import Settings from './Settings';
import Modal from 'react-bootstrap4-modal';
import {withRouter} from "react-router-dom";
import { inject, observer } from 'mobx-react';
import AppRoutes from './AppRoutes';
import SignUpScreen from './SignUpScreen';
import firebase from '../firebase/firebase';

@inject('userStore')
@observer
class BaseLayout extends Component {
    constructor(props){
      super(props);
      this.state = {
        signUpVisible:false,
        signInVisible:false,
        isSignedIn:false,
        user:null
      }
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    backgroundClick = () => {
      this.props.userStore.modalVisible = false;
    }

    openSettings = () => {
      this.props.userStore.modalVisible = true;
    }

    closeModal = () => {
      this.setState({
        signUpVisible:false,
        signInVisible:false
      })
    }

    openSignUp = () => {
      this.setState({
        signUpVisible:true
      })
    }

    openSignIn = () => {
      this.setState({
        signInVisible:true
      })
    }

    render() {
      const {signUpVisible, signInVisible, user} = this.state;
        return (
            <React.Fragment>
            <Modal visible={this.props.userStore.modalVisible} className="modal slide-up" dialogClassName="modal-dialog" fade={true} onClickBackdrop={this.backgroundClick}>
              <Settings onClickBackdrop={this.backgroundClick}/>
            </Modal>
            <Modal visible={signUpVisible} className="modal slide-up" dialogClassName="modal-dialog" fade={true} onClickBackdrop={this.closeModal}>
              <SignUpScreen onClickBackdrop={this.closeModal}/>
            </Modal>
            <div className="navbar-area">
                <div className="tracer-responsive-nav">
                    <div className="container">
                        <div className="tracer-responsive-menu">
                            <div className="logo">
                                <a href="index.html">
                                    <img src={require('../img/logo_dark.png')} alt="logo"/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="tracer-nav">
                    <div className="container-fluid">
                        <nav className="navbar navbar-expand-md navbar-light">
                            <a className="navbar-brand" href="index.html">
                              <img src={require('../img/logo_dark.png')} alt="logo"/>
                            </a>

                            <div className="collapse navbar-collapse mean-menu">
                                <ul className="navbar-nav">
                                    <li className="nav-item"><a href="#" className="nav-link">Home</a>
                                    </li>
                                    <li className="nav-item"><a href="#" className="nav-link">How It Works <i className='bx bx-chevron-down'></i></a>
                                        <ul className="dropdown-menu">
                                            <li className="nav-item"><a href="about-us-1.html" className="nav-link">Getting Deals</a></li>
                                            <li className="nav-item"><a href="about-us-2.html" className="nav-link">Giving Deals</a></li>
                                        </ul>
                                    </li>
                                    {!this.props.userStore.is_authenticated ?
                                    <li className="nav-item"><a href="#" onClick={this.openSignIn} className="nav-link">Sign In</a></li>
                                    :
                                    <li className="nav-item"><a href="#" className="nav-link">Account <i className='bx bx-chevron-down'></i></a>
                                        <ul className="dropdown-menu">
                                            <li className="nav-item"><a href="about-us-1.html" className="nav-link">Notification Settings</a></li>
                                            <li className="nav-item"><a href="#" onClick={()=>this.props.userStore.logout()} className="nav-link">Sign Out</a></li>
                                        </ul>
                                    </li>
                                    }
                                </ul>
                                <div className="others-option d-flex align-items-center">
                                    <div className="option-item">
                                      {this.props.userStore.is_authenticated ?
                                        <a href="#" className="default-btn" onClick={()=>this.props.userStore.logout()}><i className="flaticon-right"></i>Sign Out<span></span></a>
                                      :
                                        <a href="#" className="default-btn" onClick={this.openSignUp}><i className="flaticon-right"></i>Sign Up<span></span></a>
                                      }
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>

                <div className="others-option-for-responsive">
                    <div className="container">
                        <div className="dot-menu">
                            <div className="inner">
                                <div className="circle circle-one"></div>
                                <div className="circle circle-two"></div>
                                <div className="circle circle-three"></div>
                            </div>
                        </div>

                        <div className="container">
                            <div className="option-inner">
                                <div className="others-option">
                                    <div className="option-item">
                                        <a href="contact.html" className="default-btn"><i className="flaticon-right"></i>Sign Up<span></span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
              <AppRoutes/>
            </div>
            <footer className="footer-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="single-footer-widget">
                                <a href="index.html" className="logo">
                                    <img src={require('../img/logo_dark.png')} alt="logo"/>
                                </a>
                                <p>What's the Deal?</p>
                            </div>
                        </div>

                    </div>
                    <div className="footer-bottom-area">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-6">
                                <p><i className='bx bx-copyright'></i>2020 All Rights Reserved. <strong>Simply Engaging Technologies, LLC.</strong> Powered by <a target="_blank" href="https://instty.com/">Instty.com</a></p>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <ul>
                                    <li><a href="privacy-policy.html">Privacy Policy</a></li>
                                    <li><a href="terms-of-service.html">Terms & Conditions</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            </React.Fragment>
        );
    }
}

export default BaseLayout;
