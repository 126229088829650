import { observable, computed, action } from "mobx";
import { connectionPost } from '../utils/connection';
import { create, persist } from 'mobx-persist';
import { auth } from '../firebase';

class UserStore {
    @persist @observable first_name = '';
    @persist @observable last_name = '';
    @persist @observable name = '';
    @persist @observable phone_number = '';
    @persist @observable firebase_uid = '';
    @persist @observable hash_id = '';
    @persist @observable token = '';
    @persist @observable id = 0;
    @persist @observable is_admin = 0;

    @computed get is_authenticated() {
    	if (this.token !== ''){
    		return this.token;
    	} else {
    		return false;
    	}
    }

    @action get_token(firebase_uid){
      return connectionPost('token', {}, {username: firebase_uid, password: null}
			).then((res) => {
        if (res.data.token){
          console.log(res)
          // should return: user
          this.token = res.data.token; // set token last as this is what triggers user to different view
          this.first_name = res.data.user.first_name;
          this.last_name = res.data.user.last_name;
          this.name = res.data.user.full_name;
          this.phone_number = res.data.user.phone_number;
          this.firebase_uid = res.data.user.firebase_uid;
          this.hash_id = res.data.user.hash_id;
          this.id = res.data.user.id;
          this.is_admin = res.data.user.is_admin;
        }
      })
      .catch((error) => {
        return error;
      });
    }

    @action setToken(newtoken){
      this.token = newtoken;
    }

    @action logout(){
      auth.doSignOut().then(()=>{
        this.first_name = '';
        this.last_name = '';
        this.name = '';
        this.phone_number = '';
        this.firebase_uid = '';
        this.id = 0;
        this.token = '';
        this.hash_id = '';
        this.is_admin = 0;
      });
    }
}

export default new UserStore();
