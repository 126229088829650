import React from 'react';

class OfferDetail extends React.Component {
  constructor(props){
    super(props);
    // The component's Local state.
    this.state = {
      activeTab:'details'
    }
  }

  renderHtml = (content) => {
    return {__html: content};
  }

  render() {
    if (this.props.offer === null) return <div>Offer not loaded</div>;
    const {offer, backgroundClickHandler} = this.props;
    const {activeTab} = this.state;
    return (
      <React.Fragment>
        <div className="modal-content-wrapper">
          <div className="modal-content">
            <div className="modal-header clearfix text-left">
              <h2>{offer.title} <small>from {offer.location_name}</small></h2>
              <button aria-label="" type="button" className="close" onClick={backgroundClickHandler} aria-hidden="true"><i className="bx bx-x"></i></button>
            </div>
            <div className="modal-body">
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 col-md-12">
                    <div className="courses-details-desc">
                      <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item"><a className={activeTab === 'details' ? "nav-link active" : "nav-link"} href="#" onClick={()=>this.setState({activeTab:'details'})}>Offer Details</a></li>
                        <li className="nav-item"><a className={activeTab === 'terms' ? "nav-link active" : "nav-link"} href="#" onClick={()=>this.setState({activeTab:'terms'})}>Terms of Offer</a></li>
                      </ul>
                      <div className="tab-content" id="myTabContent">
                          <div className={activeTab === 'details' ? "tab-pane fade show active" : "tab-pane fade"}>
                              <div className="courses-curriculum">
                                  <h3>Offer Details</h3>
                                  <div dangerouslySetInnerHTML={this.renderHtml(offer.details)}/>
                              </div>
                          </div>
                          <div className={activeTab === 'terms' ? "tab-pane fade show active" : "tab-pane fade"}>
                              <div className="courses-curriculum">
                                  <h3>Terms of Offer</h3>
                                  {offer.business_terms ?
                                  <div dangerouslySetInnerHTML={this.renderHtml(offer.business_terms.terms)}/>
                                  : 'N/A'}
                              </div>
                          </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12">
                    <div className="courses-details-info">
                      <div class="image">
                        <img src={offer.sys_category ? offer.sys_category.img : 'https://via.placeholder.com/750x500'} alt="offer image"/>
                      </div>
                      <ul className="info">
                        <li className="price">
                          <div className="d-flex justify-content-between align-items-center">
                            {offer.sys_offer_type_id && offer.sys_offer_type_id === 3 ?
                              <React.Fragment>
                              <span><i className="flaticon-price-tag"></i> Offer</span> <small style={{fontSize:'12px',lineHeight:'14px', fontWeight:'bold'}} className="pull-right">Buy One<br/>{offer.offer_value}</small>
                              </React.Fragment>
                            :
                            <React.Fragment>
                              <span><i className="flaticon-price-tag"></i> Offer</span> {offer.offer_value}
                              {offer.sys_offer_type_id && offer.sys_offer_type_id === 1 ? ' Off' : null}
                              {offer.sys_offer_type_id && offer.sys_offer_type_id === 2 ? ' Off' : null}
                            </React.Fragment>
                            }
                          </div>
                        </li>
                        <li>
                          <div className="d-flex justify-content-between align-items-center">
                              <span><i className="flaticon-agendas"></i> Availability</span>
                              {offer.availability}
                          </div>
                        </li>
                      </ul>
                      <div className="btn-box">
                        <a href={offer.offer_url} target="_blank" className="default-btn"><i className="flaticon-price-tag"></i>Book Now<span></span></a>
                      </div>
                      <div className="courses-share">
                        <div className="share-info">
                          <span>Share This Offer <i className="flaticon-share"></i></span>
                          <ul className="social-link">
                            <li><a href="#" className="d-block" target="_blank"><i className='bx bxl-facebook'></i></a></li>
                            <li><a href="#" className="d-block" target="_blank"><i className='bx bxl-twitter'></i></a></li>
                            <li><a href="#" className="d-block" target="_blank"><i className='bx bxl-instagram'></i></a></li>
                            <li><a href="#" className="d-block" target="_blank"><i className='bx bxl-linkedin'></i></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default OfferDetail;
