// Import FirebaseAuth and firebase.
import React from 'react';
import { inject } from 'mobx-react';
import FirebaseAuth from 'react-firebaseui/FirebaseAuth';
import firebase from '../firebase/firebase';
import { connectionPost } from '../utils/connection';

// // Configure Firebase.
// const config = {
//   apiKey: 'AIzaSyAeue-AsYu76MMQlTOM-KlbYBlusW9c1FM',
//   authDomain: 'myproject-1234.firebaseapp.com',
//   // ...
// };
// firebase.initializeApp(config);
@inject('userStore')
class SignUpScreen extends React.Component {
  constructor(props){
    super(props);
    // The component's Local state.
    this.state = {}
  }


  // Configure FirebaseUI.
  uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      {provider:firebase.auth.PhoneAuthProvider.PROVIDER_ID, fullLabel:'Sign up with Phone'},
      {provider:firebase.auth.GoogleAuthProvider.PROVIDER_ID, fullLabel:'Sign up with Google'},
      {provider:firebase.auth.EmailAuthProvider.PROVIDER_ID, fullLabel:'Sign up with Email/Password'}
    ],
    callbacks: {
      signInSuccess: (currentUser, credential, redirectUrl) => {
        console.log(currentUser);
        return true;
      },
      signInSuccessWithAuthResult: (authResult, redirectUrl) => {
        // User successfully signed in.
        // Return type determines whether we continue the redirect automatically
        // or whether we leave that to developer to handle.
        console.log(authResult, redirectUrl)
        // actions you want go here
        return true;
      },
    }
  };

  // Listen to the Firebase Auth state and set the local state.
  componentDidMount() {
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
      (user) => {
        if (user && !this.props.userStore.is_authenticated){
          let firebase_uid = user.uid;
          connectionPost('users/register', {firebase_uid: firebase_uid, user:user}, {}
          ).then((res) => {
            this.props.userStore.get_token(firebase_uid);
            this.props.onClickBackdrop();
          }).catch((error) => {
            console.log(error);
            return error;
          });
        }
      }
    );
  }

  // Make sure we un-register Firebase observers when the component unmounts.
  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  render() {
    const {onClickBackdrop} = this.props;
    return (
      <div className="modal-content-wrapper">
        <div className="modal-content">
          <div className="modal-header clearfix text-left">
            <h2>Sign Up</h2>
            <button aria-label="" type="button" className="close" onClick={onClickBackdrop} aria-hidden="true"><i className="bx bx-x"></i></button>
          </div>
          <div className="modal-body">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <p>Welcome - Please Sign Up</p>
                  <FirebaseAuth uiConfig={this.uiConfig} firebaseAuth={firebase.auth()}/>
                  <p>Already have an account?  <a href="/login">Sign In here!</a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SignUpScreen;
