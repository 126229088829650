import React from 'react';

class OfferCard extends React.Component {
  constructor(props){
    super(props);
    // The component's Local state.
    this.state = {}
  }

  renderHtml = (content) => {
    return {__html: content};
  }

  render() {
    const {offer, openOffer} = this.props;
    return (
      <div className="col-lg-4 col-md-6">
          <div className="single-courses-box">
              <div className="courses-image">
                  <a href="#" onClick={()=>openOffer(offer)} className="d-block image">
                    <img src={offer.sys_category ? offer.sys_category.img : 'https://via.placeholder.com/750x500'} alt="offer image"/>
                  </a>
                  <a href="#" className="fav"><i className="flaticon-heart"></i></a>
                  {offer.sys_offer_type ?
                    <React.Fragment>
                    {offer.sys_offer_type.id === 1 ?
                    <div className="price shadow free" style={{fontSize:"14px"}}>{offer.offer_value} off</div>
                    :
                    offer.sys_offer_type.id === 2 ?
                    <div className="price shadow free" style={{fontSize:"12px"}}>{offer.offer_value} off</div>
                    :
                    offer.sys_offer_type.id === 3 ?
                    <div className="price shadow" style={{fontSize:"12px"}}>BOGO</div>
                    : null}
                    </React.Fragment>
                    : null
                  }
              </div>
              <div className="courses-content">
                  <div className="course-author d-flex align-items-center">
                      <img src={offer.location_img_url} className="rounded-circle" alt="image"/>
                      <a href={"/offers/" + offer.location.location_code}><span>{offer.location_name}</span></a>
                  </div>
                  <h3><a href="#" onClick={()=>openOffer(offer)}>{offer.title}</a></h3>
                  <div dangerouslySetInnerHTML={this.renderHtml(offer.details)}/>
                  <ul className="courses-box-footer d-flex justify-content-between align-items-center">
                      <li>
                          <i className='flaticon-agendas'></i> Available {offer.availability}
                      </li>
                  </ul>
              </div>
          </div>
      </div>);
  }
}

export default OfferCard;
