import React, {Component} from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import { inject, observer } from 'mobx-react';
import { connectionGet } from './utils/connection';
import BaseLayout from './common/BaseLayout';
import UserLayout from './common/UserLayout';
import firebase from './firebase/firebase';

@inject('userStore')
@withRouter
@observer
class App extends Component {
  constructor(props){
    super(props);
    // The component's Local state.
    this.state = {
      isSignedIn: false // Local signed-in state.
    }
  }

  // componentDidMount() {
  //   this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
  //     (user) => {this.setState({isSignedIn: !!user})}
  //   );
  // }
  //
  // // Make sure we un-register Firebase observers when the component unmounts.
  // componentWillUnmount() {
  //   this.unregisterAuthObserver();
  // }

  // componentDidMount(){
  //   connectionGet('test', {}, {})
  //   .then((res)=>{
  //     console.log(res);
  //   })
  //   .catch((error)=>{console.log(error);});
  // }
  //
  render(){
    return (<BaseLayout/>);
    if (this.state.isSignedIn){
      return (
        <UserLayout/>
      )
    } else {
      return (
        <BaseLayout/>
      )
    }
  }
}
export default App;
