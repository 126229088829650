import React, { Component } from 'react';
// import AppRoutes from './AppRoutes';
// import Gravatar from 'react-gravatar';
import Settings from './Settings';
import Modal from 'react-bootstrap4-modal';
import {withRouter} from "react-router-dom";
import { inject, observer } from 'mobx-react';

@inject('userStore')
@observer
class UserLayout extends Component {
    constructor(props){
      super(props);
    }

    backgroundClick = () => {
      this.props.userStore.modalVisible = false;
    }

    openSettings = () => {
      this.props.userStore.modalVisible = true;
    }

    render() {
        return (
            <React.Fragment>
            <div className="header">
              <div className="container">
                <div className="header-inner header-md-height">
                  <a href="#" className="btn-link toggle-sidebar d-lg-none header-icon sm-p-l-0 btn-icon-link" data-toggle="horizontal-menu">
                    <i className="pg-icon">menu</i>
                  </a>
                  <div className="">
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="pull-left p-r-10 fs-14 font-heading d-lg-inline-block d-none">
                      <span className="semi-bold"></span>
                    </div>
                    <div className="dropdown pull-right d-lg-block">
                      <button className="profile-dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" aria-label="profile dropdown">
                        Temp
                      </button>
                      <div className="dropdown-menu dropdown-menu-right profile-dropdown" role="menu">
                        <a href="/" className="dropdown-item" onClick={()=>this.props.userStore.logout()}>Logout</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="header-inner justify-content-start header-lg-height title-bar">
                </div>
                <div className="menu-bar header-sm-height" data-pages-init='horizontal-menu' data-hide-extra-li="4">
                </div>
              </div>
            </div>
            <div className="page-container">
              Hello World
            </div>
            </React.Fragment>
        );
    }
}

export default UserLayout;
