import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import OfferCard from './OfferCard';
import OfferDetail from './OfferDetail';
import Modal from 'react-bootstrap4-modal';
import io from 'socket.io-client';
import { connectionGet, connectionPost, connectionPut } from '../utils/connection';

@inject('userStore')
class Offers extends Component {
    socket;
    constructor(props){
      super(props);
      this.state = {
        offer_list:[],
        connectionState:null,
        location:{id:0, name:'Loading'},
        offerVisible:false,
        selectedOffer:null,
        subscription:null
      }
    }

    initOfferlist = (data) => {
      this.setState({
        offer_list:data
      })
    }

    updateList = (data) => {
      console.log(data);
      let tmp = Object.assign([], this.state.offer_list);
      let index = tmp.findIndex(x => x.id == data.id);
      if (parseInt(data.is_published) === 1 && index === -1){
        // Add
        tmp.push(data);
      } else if (parseInt(data.is_published) === 1 && index !== -1) {
        // update
        tmp[index] = data;
      } else if (parseInt(data.is_published) === 0 && index !== -1) {
        // remove
        if (index !== -1) tmp.splice(index, 1);
      }
      this.setState({
        offer_list:tmp
      })
    }

    disconnect = () => {
      if (this.socket){
        this.socket.disconnect();
      }
    }

    connect = () => {
      if (this.socket){
        this.socket.connect();
      }
    }

    setConnectionState = (state) => {
      console.log(state);
      this.setState({
        connectionState:state
      })
    }

    updateSubscription = (subscribe) => {
      if (this.state.location && this.props.userStore.is_authenticated){
        if (subscribe){
          connectionPost('location-offers-subscribe/' + this.state.location.hash_id, {user_id:this.props.userStore.id}, {}
          ).then((res) => {
            if (res.data.errors === 0){
              this.setState({
                subscription:res.data.subscription
              }, ()=>alert(res.data.message));
            } else {
              alert(res.data.message);
            }
          });
        } else {
          if (this.state.subscription){
            connectionPut('location-offers-subscribe/' + this.state.location.hash_id, {subscription:this.state.subscription}, {}
            ).then((res) => {
              if (res.data.errors === 0){
                this.setState({
                  subscription:null
                }, ()=>alert(res.data.message));
              } else {
                alert(res.data.message);
              }
            });
          } else {
            alert('You are not subscribed');
          }
        }
      } else {
        alert('You need to be logged in and location must be set');
      }
    }

    componentDidMount(){
      let user_id = null;
      if (this.props.userStore.hash_id !== 0){
        user_id = this.props.userStore.hash_id;
      }
      if (this.props.match.params.shortCode){
        connectionGet('location-offers/' + this.props.match.params.shortCode, {user_id:user_id}, {}
        ).then((res) => {
          if (res.data.errors === 0){
            this.setState({
              location:res.data.location,
              subscription:res.data.subscription
            },()=>{
              let endPoint = "/";
              this.socket = io.connect(endPoint, {
                'reconnection': true,
                'reconnectionDelay': 500,
                'reconnectionAttempts': 10
              });
              this.socket.on('connect', ()=>{
                this.socket.emit('join-offers', {room:this.state.location.id}, rsp => {
                  console.log(rsp)
                });
                this.setConnectionState('connected');
              })
              this.socket.on('joinOffersLoad', (data, cb) => {
                this.initOfferlist(data);
              })
              this.socket.on("offersUpdate", (data, cb) => {
                this.updateList(data);
                cb();
              });
              this.socket.on("disconnect", ()=>{
                this.socket.emit('leave-offers', {room:this.state.location.id}, rsp => {
                  console.log(rsp)
                });
                this.setConnectionState('disconnected');
              })
            });
          } else {
            alert(res.data.message);
          }
        });
      } else {
        let endPoint = "/";
        this.socket = io.connect(endPoint, {
          'reconnection': true,
          'reconnectionDelay': 500,
          'reconnectionAttempts': 10
        });
        this.socket.on('connect', ()=>{
          this.socket.emit('join-offers', {room:this.state.location.id}, rsp => {
            console.log(rsp)
          });
          this.setConnectionState('connected');
          let tmp = Object.assign({}, this.state.location);
          tmp.name = 'All';
          this.setState({
            location:tmp
          });
        })
        this.socket.on('joinOffersLoad', (data, cb) => {
          this.initOfferlist(data);
        })
        this.socket.on("offersUpdate", (data, cb) => {
          this.updateList(data);
          cb();
        });
        this.socket.on("disconnect", ()=>{
          this.socket.emit('leave-offers', {room:this.state.location.id}, rsp => {
            console.log(rsp)
          });
          this.setConnectionState('disconnected');
        })
      }
    }

    backgroundClick = () => {
      this.setState({
        selectedOffer:null,
        offerVisible:false
      })
    }

    openOffer = (offer) => {
      this.setState({
        selectedOffer:offer,
        offerVisible:true
      })
    }

    render(){
      const {offer_list, loading, location, offerVisible, selectedOffer, subscription} = this.state;
      return (
        <>
        <Modal visible={offerVisible} className="modal slide-up" dialogClassName="modal-dialog modal-xl" fade={true} onClickBackdrop={this.backgroundClick}>
          {selectedOffer !== '' ?
          <OfferDetail backgroundClickHandler={this.backgroundClick} offer={selectedOffer}/>
          : <div></div>}
        </Modal>
        <section className="page-title-area">
          <div className="container">
            <div className="page-title-content">
              <h2>{location.name} Offers</h2>
            </div>
            <div className="tracer-grid-sorting row">
              <div className="col-12">
              {location.id && this.props.userStore.is_authenticated ?
                subscription ?
                <button className="btn btn-success" onClick={()=>this.updateSubscription(false)}>Subscribed - Click to Unsubscribe</button>
                :
                <React.Fragment>
                <button className="btn btn-primary" onClick={()=>this.updateSubscription(true)}>Subscribe to {location.name}*</button>
                </React.Fragment>
              : null
              }
              </div>
            </div>
          </div>
          <div className="shape-img1"><img src={"../" + process.env.PUBLIC_URL + "assets/img/shape/shape1.svg"} alt="image"/></div>
          <div className="shape-img2"><img src={"../" + process.env.PUBLIC_URL + "assets/img/shape/shape2.png"} alt="image"/></div>
          <div className="shape-img3"><img src={"../" + process.env.PUBLIC_URL + "assets/img/shape/shape3.png"} alt="image"/></div>
        </section>
        <section className="courses-area ptb-40 bg-fafafb">
          <div className="container">
            <div className="row">
            {offer_list.length > 0 ?
              offer_list.map((offer, offer_idx)=>
                <OfferCard key={offer_idx} offer={offer} openOffer={this.openOffer}/>
              )
            : 'No Offers'}
            </div>
            <small className="text-hint">*If you subscribe you will receive sms notifications when deals are made available. Standard sms fees will apply.</small>
          </div>
        </section>
        </>
      );
    }
}

export default Offers;


// <div className="tracer-grid-sorting row align-items-center">
//     <div className="col-lg-6 col-md-6 result-count">
//         <p>We found <span className="count">14</span> deals available</p>
//     </div>
//
//     <div className="col-lg-6 col-md-6 ordering">
//         <div className="select-box">
//             <label>Sort By:</label>
//             <select>
//                 <option>Default</option>
//                 <option>Popularity</option>
//                 <option>Latest</option>
//                 <option>Price: low to high</option>
//                 <option>Price: high to low</option>
//             </select>
//         </div>
//     </div>
// </div>
//
